export default {
  50: '#ceffff',
  100: '#b7fffd',
  200: '#82ffff',
  300: '#47fcff',
  400: '#5cdae2',
  500: '#40d0dc',
  600: '#4aafb7',
  700: '#449a9e',
  800: '#258d93',
  900: '#1e6969',
};
