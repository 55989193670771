import type { CountryCode, PhoneNumber } from 'libphonenumber-js/min';
import type { SpeedDial } from '#utils/phone.ts';

import React from 'react';
import { faPhone } from '@fortawesome/pro-solid-svg-icons';
import {
  Button,
  Pagination,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import FontAwesomeScalableIcon from '#components/FontAwesomeScalableIcon.tsx';
import { PhoneInput } from '#components/PhoneInput.tsx';
import { grey } from '#lib/mui/colors';
import { WidgetCard } from './WidgetCard';

interface DialerProps {
  onCall: (phoneNumber: string, userName: string) => void;
  callState: 'inactive' | 'idle' | 'dialing' | 'ringing';
  speedDials: SpeedDial[];
}

// NOTE: These are the only countries the Zoom dialer currently supports
// To add support for more, you'd have to update the Zoom audio conferencing
// plan to include the desired countries in the account.
const ZOOM_DIALER_COUNTRIES: CountryCode[] = [
  'AU',
  'BR',
  'CA',
  'CH',
  'DE',
  'KR',
  'KY',
  'MX',
  'NG',
  'NL',
  'PH',
  'US',
];

export const SPEED_DIALS_PER_PAGE = 5;

export function Dialer({ onCall, callState, speedDials }: DialerProps) {
  const [inviteeName, setInviteeName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState<PhoneNumber | null>(
    null
  );
  const [phoneIsValid, setPhoneIsValid] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [page, setPage] = React.useState(1);

  // Filter and paginate speed dials in one go
  const paginatedSpeedDials = speedDials
    .filter((dial) =>
      dial.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice((page - 1) * SPEED_DIALS_PER_PAGE, page * SPEED_DIALS_PER_PAGE);

  return (
    <WidgetCard title="Call Someone" fullHeight data-testid="dialer">
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (phoneIsValid && phoneNumber) {
            onCall(phoneNumber.formatInternational(), inviteeName);
          }
        }}
      >
        <Stack direction="column" spacing={2}>
          <TextField
            label="Invitee Name"
            placeholder="Name"
            fullWidth
            value={inviteeName}
            onChange={(event) => setInviteeName(event.target.value)}
          />
          <PhoneInput
            labelText="Phone Number"
            permittedCountries={ZOOM_DIALER_COUNTRIES}
            onPhoneNumberChanged={(phoneNumber) => {
              setPhoneIsValid(phoneNumber ? phoneNumber.isValid() : false);
              setPhoneNumber(phoneNumber);
            }}
          />
          <Button
            type="submit"
            startIcon={<FontAwesomeScalableIcon icon={faPhone} />}
            fullWidth
            disabled={!phoneIsValid || !phoneNumber || callState !== 'idle'}
          >
            Call
          </Button>
          {speedDials.length > 0 && (
            <>
              <Typography variant="subtitle1">Speed Dials</Typography>
              {speedDials.length > SPEED_DIALS_PER_PAGE && (
                <TextField
                  label="Search by name"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setPage(1); // Reset to page 1 whenever the search term changes
                  }}
                  fullWidth
                />
              )}
              <Stack direction="column" spacing={2}>
                {paginatedSpeedDials.length > 0 ? (
                  paginatedSpeedDials.map((dial) => (
                    <Tooltip key={dial.id} title={dial.formattedNumber}>
                      <Button
                        fullWidth
                        sx={{
                          justifyContent: 'space-between',
                          color: grey[900],
                          backgroundColor: grey[300],
                          '&:hover,&:focus': { backgroundColor: grey[400] },
                        }}
                        onClick={() => onCall(dial.number, dial.name)}
                      >
                        {dial.name}
                        <FontAwesomeScalableIcon
                          icon={faPhone}
                          sx={{ color: 'green' }}
                        />
                      </Button>
                    </Tooltip>
                  ))
                ) : (
                  <Typography variant="body1" my={2} textAlign="center">
                    No Results Found
                  </Typography>
                )}
              </Stack>
              {speedDials.length > SPEED_DIALS_PER_PAGE && (
                <Pagination
                  count={Math.ceil(speedDials.length / SPEED_DIALS_PER_PAGE)}
                  page={page}
                  onChange={(_, value) => setPage(value)}
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                />
              )}
            </>
          )}
        </Stack>
      </form>
    </WidgetCard>
  );
}
