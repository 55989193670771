export default {
  50: '#eefff5',
  100: '#c6fbda',
  200: '#96e9b5',
  300: '#84f3b1',
  400: '#65ed9f',
  500: '#45e78d',
  600: '#57d489',
  700: '#31c272',
  800: '#29b066',
  900: '#2f7e4f',
  1000: '#276f44',
  1100: '#1e5c37',
};
