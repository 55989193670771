export default {
  50: '#f2e9f8',
  100: '#dec9ee',
  200: '#c8a4e4',
  300: '#b37dd8',
  400: '#a25fce',
  500: '#9140c3',
  600: '#883abd',
  700: '#7b31b4',
  800: '#712aac',
  900: '#5f1c9e',
};
