import type {
  IconProp,
  IconDefinition as SvgIconDefinition,
} from '@fortawesome/fontawesome-svg-core';
import type { IconDefinition as SolidSvgIconDefinition } from '@fortawesome/pro-solid-svg-icons';
import type { SvgIconProps } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SvgIcon } from '@mui/material';

type FontAwesomeScalableIconProps = SvgIconProps & {
  icon: SvgIconDefinition | SolidSvgIconDefinition;
};

/**
 * Borrowed from the dashboard which in turn borrows it from:
 *
 * @see https://codesandbox.io/s/dr59e?file=/src/components/FontAwesomeScalableIcon.tsx
 */
export default function FontAwesomeScalableIcon({
  icon,
  ...props
}: FontAwesomeScalableIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 20"
        preserveAspectRatio="xMidYMid meet"
      >
        <FontAwesomeIcon icon={icon as IconProp} />
      </svg>
    </SvgIcon>
  );
}
