import type { ThemeOptions } from '@mui/material/styles';
import type { CSSProperties } from 'react';

import { createTheme } from '@mui/material/styles';

import { green, grey, mendTeal, pink, red, yellow } from './colors';

declare module '@mui/material/styles' {
  interface Palette {
    navbar: Palette['primary'];
  }

  interface PaletteOptions {
    navbar?: PaletteOptions['primary'];
  }

  interface TypographyVariants {
    captionLight: CSSProperties;
    subtitle3: CSSProperties;
  }

  // So it can be used within `createTheme` below
  interface TypographyVariantsOptions {
    captionLight?: CSSProperties;
    subtitle3?: CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    ['extra-large']: true;
  }
}

// So it can be used in the component's variant prop
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    captionLight: true;
    subtitle3: true;
  }
}

const fontFamily = ['"Raleway"', 'sans-serif'].join(',');

const avatarOverrides = {
  width: 32,
  height: 32,
  fontSize: 16,
  fontWeight: 500,
};

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: mendTeal[700],
    },
    secondary: {
      main: pink[1000],
    },
    navbar: {
      /**
       * Must be kept in sync with primary color in case it doesn't get
       * overridden.
       */
      main: mendTeal[700],
    },
    success: {
      main: green[900],
    },
    error: {
      main: red[600],
    },
    warning: {
      main: yellow[700],
    },
  },
  typography: {
    fontFamily,
    h1: { fontWeight: 500, color: grey[900] },
    h2: { fontWeight: 500, color: grey[900] },
    h3: { fontWeight: 500, color: grey[900] },
    h4: { fontWeight: 500, color: grey[900] },
    h5: { fontWeight: 500, color: grey[900] },
    h6: { fontWeight: 500, color: grey[900] },
    subtitle1: { fontWeight: 500, color: grey[800] },
    subtitle2: { fontWeight: 500, color: grey[800] },
    subtitle3: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '27px',
      color: grey[800],
    },
    body1: { fontWeight: 500, color: grey[800] },
    body2: { fontWeight: 500, color: grey[800] },
    button: { fontWeight: 600 },
    caption: { fontWeight: 500, color: grey[800] },
    captionLight: {
      /**
       * Since this is a custom variant we need to define all properties,
       * everything but the color was copied from the "caption" variant.
       */
      fontFamily,
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      color: grey[600],
    },
    overline: { fontWeight: 500, color: grey[600] },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: (props) => ({
          ...avatarOverrides,
          backgroundColor: props.theme.palette.primary.main,
          color: props.theme.palette.primary.contrastText,
        }),
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: (props) => ({
          ...avatarOverrides,
          backgroundColor: props.theme.palette.primary.main,
          color: props.theme.palette.primary.contrastText,
        }),
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiButton: {
      variants: [
        {
          props: { size: 'extra-large' },
          style: {
            padding: '12px 32px',
            fontSize: '1rem',
            lineHeight: 1.5,
            letterSpacing: '0.009375em', // 0.15px
          },
        },
        {
          props: { size: 'extra-large', variant: 'outlined' },
          style: {
            padding: '11px 31px', // To account for border in this variant
          },
        },
      ],
      defaultProps: {
        variant: 'contained',
        color: 'primary',
      },
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: red[600],
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiStack: {
      defaultProps: {
        direction: 'row',
        spacing: 1,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 12,
          color: grey[800],
        },
      },
    },
    MuiTablePagination: {
      defaultProps: {
        showFirstButton: true,
        showLastButton: true,
      },
      // Override BS+Main
      styleOverrides: {
        selectLabel: {
          marginBottom: 0,
        },
        displayedRows: {
          marginBottom: 0,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: grey[600],
          color: 'white',
          padding: 6,
          fontSize: 12,
        },
        arrow: {
          color: grey[600],
          borderColor: grey[600],
        },
      },
    },
  },
};

const baseTheme = createTheme(themeOptions);

/**
 * Add the full navbar color palette so it can be used appropriately since mui
 * doesn't automatically add the missing values for custom colors.
 *
 * @see https://mui.com/material-ui/customization/palette/#custom-colors
 * @see https://mui.com/material-ui/customization/palette/#generate-tokens-using-augmentcolor-utility
 */
export const theme = createTheme(baseTheme, {
  palette: {
    navbar: baseTheme.palette.augmentColor({
      color: { main: baseTheme.palette.navbar.main },
      name: 'navbar',
    }),
  },
});

export type CustomTheme = typeof theme;
