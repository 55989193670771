import type { Response } from '#types/common';

import { BRANDING_CONFIG } from '#utils/properties';
import { api } from '../http-clients';

/**
 * `undefined` is possible if we pass a non-existent key or the property
 * doesn't exist exist at any given point.
 */
export type Properties = Record<
  string,
  boolean | number | string | Record<string, unknown> | unknown[] | undefined
>;

export function getPublicProperties(
  orgId?: string | number
): Promise<Properties> {
  return api
    .get<
      Response<{ properties: Properties }>
    >(`/property/public${orgId ? '/' + orgId : ''}`)
    .then((response) => response.data.payload.properties);
}

/**
 * Convert the branding config property from an object (if set) to a string to
 * improve optimizations based on equality checks and save the result to local
 * storage.
 */
function saveStringifiedBrandingConfig(properties: Properties): Properties {
  const brandingConfig = properties[BRANDING_CONFIG];

  try {
    if (
      typeof brandingConfig === 'object' &&
      brandingConfig !== null &&
      !Array.isArray(brandingConfig)
    ) {
      const stringifiedBrandingConfig = JSON.stringify(brandingConfig);
      properties[BRANDING_CONFIG] = stringifiedBrandingConfig;
      window.localStorage.setItem(
        'last-custom-branding',
        stringifiedBrandingConfig
      );
    } else {
      window.localStorage.removeItem('last-custom-branding');
    }
  } catch (error) {
    // Do nothing
  }

  return properties;
}

export function getProperties(): Promise<Properties> {
  return api
    .get<Response<{ properties: Properties }>>('/property')
    .then((response) =>
      saveStringifiedBrandingConfig(response.data.payload.properties)
    );
}
