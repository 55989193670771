import { Card, Divider, Typography } from '@mui/material';

import { styled } from '#lib/mui/styled';

type WidgetCardProps = {
  title: string;
  fullHeight?: boolean;
  children: React.ReactNode;
  'data-testid'?: string;
};

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'fullHeight',
})<{ fullHeight?: boolean }>(({ theme, fullHeight }) => ({
  borderRadius: theme.spacing(0.75),
  borderTop: `${theme.spacing(1)} solid ${theme.palette.primary.main}`,
  padding: theme.spacing(3, 2),
  minHeight: fullHeight ? '100%' : undefined,
  display: 'flex',
  flexDirection: 'column',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0, 3, 0),
}));

const StyledContents = styled('div')({
  flexGrow: 1,
});

/**
 * This card was created as a wrapper to emulate our dashboard widget appearance since we
 * won't be able to just copy/paste the existing dashboard widgets verbatim.
 */
export function WidgetCard(props: WidgetCardProps) {
  return (
    <StyledCard
      elevation={3}
      fullHeight={props.fullHeight}
      data-testid={props['data-testid']}
    >
      <Typography component="span" variant="h6">
        {props.title}
      </Typography>

      <StyledDivider />

      <StyledContents>{props.children}</StyledContents>
    </StyledCard>
  );
}
