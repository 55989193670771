export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isEmailValid(value: string): boolean {
  return REGEX_EMAIL.test(value);
}

/**
 * Based on https://stackoverflow.com/a/51564734
 */
export function hex2rgba(hex: string, alpha = 1): string {
  const matches = hex.match(/\w\w/g);
  if (!matches || matches.length !== 3) {
    throw new Error(`Could not convert \`${hex}\` to rgba`);
  }
  const [r, g, b] = matches.map((x) => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function removeSpecialCharacters(value: string): string {
  return value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/\s/]/g, '');
}
