export default {
  50: '#d0f1ff',
  100: '#a5ebff',
  200: '#8cd6fe',
  300: '#63c6fc',
  400: '#4bb9fb',
  500: '#41adf9',
  600: '#3e9fea',
  700: '#398bd5',
  800: '#357ac1',
  900: '#2d5a9e',
};
