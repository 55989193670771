import type { TableCellProps, TableRowProps } from '@mui/material';
import type {
  Cell,
  HeaderGroup,
  IdType,
  Row,
  TableInstance,
} from 'react-table';
import type { SubRowProps as BaseSubRowProps } from './SubRow';

import * as React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import { grey } from '#lib/mui/colors';
import { styled } from '#lib/mui/styled';
import { SubRow as BaseSubRow } from './SubRow';

const defaultPropGetter = () => ({});

const StyledTableBody = styled(TableBody)({
  '& > [role="row"]:hover': {
    backgroundImage:
      'linear-gradient(rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04))',
  },
}) as typeof TableBody;

const defaultTruncatedColumnIds: string[] = [];

export type SharedTableProps<
  T extends Record<string, unknown>,
  P extends Record<string, unknown>,
> = {
  tableRef?: React.Ref<HTMLTableElement>;
  instance: TableInstance<T>;
  /**
   * Prop getter for the table header rows.
   */
  getHeaderRowProps?: (row: HeaderGroup<T>) => TableRowProps;
  /**
   * Prop getter for the table header cells.
   */
  getHeaderCellProps?: (cell: HeaderGroup<T>) => TableCellProps;
  /**
   * Prop getter for the table body rows.
   */
  getRowProps?: (row: Row<T>) => TableRowProps;
  /**
   * Prop getter for the table body cells.
   */
  getCellProps?: (cell: Cell<T>) => TableCellProps;
  /**
   * Prop getter for the `column.render(...)` function when rendering a cell,
   * the props are passed to the cell component, not the UI unlike
   * `getCellProps`.
   */
  getCellRendererProps?: (cell: Cell<T>) => P;
  /**
   * Object to define each column header's alignment.
   */
  headerAlignmentMapper?: Record<IdType<T>, TableCellProps['align']>;
  truncatedColumnsIds?: IdType<T>[];
  SubRow?: (props: BaseSubRowProps<T, P>) => JSX.Element;
};

export default function Table<
  T extends Record<string, unknown>,
  P extends Record<string, unknown>,
>({
  tableRef,
  instance,
  getHeaderRowProps = defaultPropGetter,
  getHeaderCellProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  getCellRendererProps,
  headerAlignmentMapper,
  truncatedColumnsIds = defaultTruncatedColumnIds,
  SubRow = BaseSubRow,
}: SharedTableProps<T, P>): JSX.Element {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setSortBy,
    page,
    rows,
    visibleColumns,
  } = instance;

  const hasPagination = page !== undefined;
  const actualRows = hasPagination ? page : rows;

  return (
    <MuiTable component="div" {...getTableProps()} ref={tableRef}>
      <TableHead component="div">
        {headerGroups.map((headerGroup) => {
          const { key: headerGroupKey, ...headerGroupProps } =
            headerGroup.getHeaderGroupProps();
          return (
            <TableRow
              key={headerGroupKey}
              component="div"
              {...headerGroupProps}
              sx={{ backgroundColor: grey[200] }}
              {...getHeaderRowProps(headerGroup)}
            >
              {headerGroup.headers.map((column) => {
                const { key: headerKey, ...headerProps } =
                  column.getHeaderProps();
                return (
                  <TableCell
                    key={headerKey}
                    component="div"
                    {...headerProps}
                    sx={{ whiteSpace: 'nowrap' }}
                    align={headerAlignmentMapper?.[column.id] ?? 'left'}
                    {...getHeaderCellProps(column)}
                  >
                    {column.render('Header')}
                    {column.canSort && (
                      <TableSortLabel
                        aria-label="Toggle sort"
                        active={column.isSorted}
                        direction={
                          column.isSorted
                            ? column.isSortedDesc
                              ? 'desc'
                              : 'asc'
                            : 'desc'
                        }
                        onClick={() =>
                          setSortBy([
                            {
                              id: column.id,
                              desc: column.isSorted
                                ? !column.isSortedDesc
                                : true,
                            },
                          ])
                        }
                      />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableHead>
      <StyledTableBody component="div" {...getTableBodyProps()}>
        {actualRows.map((row) => {
          prepareRow(row);
          const { key: rowKey, ...rowProps } = row.getRowProps();
          return (
            <React.Fragment key={rowKey}>
              <TableRow component="div" {...rowProps} {...getRowProps(row)}>
                {row.cells.map((cell) => {
                  const { key: cellKey, ...cellProps } = cell.getCellProps();
                  return (
                    <TableCell
                      key={cellKey}
                      component="div"
                      {...cellProps}
                      {...getCellProps(cell)}
                    >
                      {cell.render('Cell', {
                        customProps: getCellRendererProps?.(cell) ?? {},
                      })}
                    </TableCell>
                  );
                })}
              </TableRow>
              {row.isExpanded && (
                <TableRow component="div" {...rowProps}>
                  <TableCell
                    component="div"
                    role="cell"
                    aria-colspan={visibleColumns.length}
                  >
                    <SubRow
                      row={row}
                      truncatedColumnsIds={truncatedColumnsIds}
                      getCellRendererProps={getCellRendererProps}
                    />
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          );
        })}
      </StyledTableBody>
    </MuiTable>
  );
}
