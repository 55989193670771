export default {
  50: '#dee6fe',
  100: '#bcc9f4',
  200: '#90a7e7',
  300: '#7a88c7',
  400: '#5d6ebb',
  500: '#4055b0',
  600: '#3a4da6',
  700: '#31429a',
  800: '#29398e',
  900: '#1c2778',
};
