import { Button } from '@mui/material';

import { styled } from '#lib/mui/styled.ts';

/**
 * A styled button for a button using Zoom's blue
 */
export const ZoomButton = styled(Button)({
  color: '#FFFFFF',
  borderColor: 'var(--zoom-blue)',
  backgroundColor: 'var(--zoom-blue)',
  '&:hover, &:focus': {
    borderColor: 'var(--zoom-blue-dark)',
    backgroundColor: 'var(--zoom-blue-dark)',
  },
}) as typeof Button;
