import type { TypographyProps } from '@mui/material';
import type { Cell, IdType, Row } from 'react-table';

import * as React from 'react';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { Box, Button, Paper, Typography } from '@mui/material';

import { styled } from '#lib/mui/styled';

export type CardField<T extends Record<string, unknown>> = {
  id: IdType<T>;
  name?: string;
};

export type BaseCardProps = {
  isInCard?: boolean;
};

export type CardProps<
  T extends Record<string, unknown>,
  P extends Record<string, unknown> = Record<string, unknown>,
> = {
  row: Row<T>;
  getCellRendererProps?: (cell: Cell<T>) => BaseCardProps & P;
};

type RootProps = {
  /**
   * Determines if the card can be expanded or not. When set to `false`, the
   * `Fields`/`FieldsList` components will ignore whatever length we passed and
   * will always display all the fields and the `Footer` component (if used)
   * won't have the toggle button.
   */
  expandable?: boolean;
  /**
   * Determines if the card is expanded initially. Ignored when `expandable` is
   * set to `false`.
   */
  defaultIsExpanded?: boolean;
  children: React.ReactNode;
};

type ListItemContextValue = {
  expandable: boolean;
  isExpanded: boolean;
  toggleIsExpanded: (toValue?: boolean) => void;
};

const ListItemContext = React.createContext<ListItemContextValue | null>(null);
ListItemContext.displayName = 'ListItemContext';

/**
 * We always provide a value from the very beginning in our `Root` component
 * below so it's safe to use the non-null assertion operator.
 */
const useIsExpanded = () => React.useContext(ListItemContext)!;

export const Root = ({
  expandable = true,
  defaultIsExpanded = false,
  children,
}: RootProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = React.useState(defaultIsExpanded);

  const value = React.useMemo(
    () => ({
      expandable,
      isExpanded,
      toggleIsExpanded: (to?: boolean) => {
        if (!expandable) return;
        setIsExpanded((v) => (to !== undefined ? to : !v));
      },
    }),
    [expandable, isExpanded]
  );

  return (
    <ListItemContext.Provider value={value}>
      <Paper elevation={3}>{children}</Paper>
    </ListItemContext.Provider>
  );
};

export const Content = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledFooter = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  alignItems: 'center',
  justifyItems: 'center',
  gap: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

type FooterProps = {
  left?: React.ReactNode;
  right?: React.ReactNode;
};

export const Footer = ({ left, right }: FooterProps): JSX.Element => {
  const { expandable, isExpanded, toggleIsExpanded } = useIsExpanded();

  return (
    <StyledFooter>
      {left && <Box gridColumn="1 / 2">{left}</Box>}
      {expandable && (
        <Box gridColumn={`${left ? '2' : '1'} / ${right ? '3' : '4'}`}>
          <Button
            size="small"
            variant="text"
            color="info"
            endIcon={
              isExpanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />
            }
            onClick={() => toggleIsExpanded()}
          >
            {`Show ${isExpanded ? 'less' : 'more'}`}
          </Button>
        </Box>
      )}
      {right && <Box gridColumn="3 / 4">{right}</Box>}
    </StyledFooter>
  );
};

const StyledFields = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  alignItems: 'start',
  gap: theme.spacing(1),
}));

const INITIAL_LENGTH = 2;

type FieldsProps = {
  length?: number;
  children: React.ReactNode;
};

export const Fields = ({
  length = INITIAL_LENGTH,
  children,
}: FieldsProps): JSX.Element => {
  const { expandable, isExpanded } = useIsExpanded();

  return (
    <StyledFields>
      {!expandable || isExpanded
        ? children
        : React.Children.toArray(children).slice(0, length)}
    </StyledFields>
  );
};

type FieldProps = {
  children: React.ReactNode;
};

export const Field = ({ children }: FieldProps): JSX.Element => (
  <React.Fragment>{children}</React.Fragment>
);

export const FieldName = (props: TypographyProps): JSX.Element => (
  <Typography
    component="span"
    variant="body2"
    color="primary.main"
    {...props}
  />
);

const StyledFieldValue = styled(Typography)({
  wordBreak: 'normal',
  overflowWrap: 'anywhere',
}) as typeof Typography;

export const FieldValue = (props: TypographyProps): JSX.Element => (
  <StyledFieldValue component="span" variant="body2" {...props} />
);

type FieldsListProps<
  T extends Record<string, unknown>,
  P extends Record<string, unknown>,
> = {
  length?: number;
  list: CardField<T>[];
  cells: Cell<T>[];
  getCellRendererProps?: (cell: Cell<T>) => P;
};

export const FieldsList = <
  T extends Record<string, unknown>,
  P extends Record<string, unknown>,
>({
  length = INITIAL_LENGTH,
  list,
  cells,
  getCellRendererProps,
}: FieldsListProps<T, P>): JSX.Element => {
  const { expandable, isExpanded } = useIsExpanded();

  const actualList = !expandable || isExpanded ? list : list.slice(0, length);

  return (
    <StyledFields>
      {actualList.map(({ id, name }) => {
        const cell = cells.find((cell) => cell.column.id === id);
        if (!cell) return null;
        return (
          <Field key={id}>
            <FieldName>{name ?? cell.render('Header')}</FieldName>
            <FieldValue>
              {cell.render('Cell', {
                customProps: getCellRendererProps?.(cell) ?? {},
              })}
            </FieldValue>
          </Field>
        );
      })}
    </StyledFields>
  );
};
