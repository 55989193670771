import type { AutocompleteProps } from '@mui/material';
import type { PhoneNumber } from '#services/api/phone';
import type {
  User as ExistingUser,
  GetUsersParams,
  GetUsersResponse,
  Group,
} from '#services/api/user';
import type { BaseAutocompleteInputProps } from '../Autocomplete.types';
import type { User } from './UsersAutocomplete.types';

import * as React from 'react';
import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material';
import { useAsyncDebounce } from 'react-table';

import useAsync from '#hooks/async';
import { phoneLookup } from '#services/api/phone';
import { getUsers } from '#services/api/user';
import { isEmailValid } from '#utils/functions';
import { getFullName } from '#utils/user';
import { getTextFieldProps } from '../Autocomplete.utils';
import * as utils from './UsersAutocomplete.utils';

type BaseValue<IncludeGroups, EnableCustomEmail, EnableCustomPhoneNumber> =
  | (IncludeGroups extends true ? Group : never)
  | (EnableCustomEmail extends true
      ? User
      : EnableCustomPhoneNumber extends true
        ? User
        : ExistingUser);

type UsersAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  IncludeGroups extends boolean | undefined = undefined,
  EnableCustomEmail extends boolean | undefined = undefined,
  EnableCustomPhoneNumber extends boolean | undefined = undefined,
> = Omit<
  AutocompleteProps<
    BaseValue<IncludeGroups, EnableCustomEmail, EnableCustomPhoneNumber>,
    Multiple,
    DisableClearable,
    false
  >,
  | 'loading'
  | 'options'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'isOptionEqualToValue'
  | 'noOptionsText'
  | 'renderInput'
  | 'renderOption'
  | 'renderTags'
  | 'freeSolo'
> &
  Omit<BaseAutocompleteInputProps, 'dataFilter'> & {
    getUsersParams?: () => Omit<GetUsersParams, 'search' | 'includeGroups'>;
    waitForMs?: number;
    includeGroups?: IncludeGroups;
    enableCustomEmail?: EnableCustomEmail;
    enableCustomPhoneNumber?: EnableCustomPhoneNumber;
  };

let userController: AbortController | undefined;
let lookupController: AbortController | undefined;

/**
 * TECH DEBT -  Refactor component to accept a "base" user type that satisfies
 * the minimum set of properties used here. This will allow us to use the
 * component with "incomplete" user types, such as the patient/provider coming
 * from an appointment object.
 */
export default function UsersAutocomplete<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  IncludeGroups extends boolean | undefined = undefined,
  EnableCustomEmail extends boolean | undefined = undefined,
  EnableCustomPhoneNumber extends boolean | undefined = undefined,
>({
  getUsersParams = utils.defaultGetUsersParams,
  waitForMs = 500,
  includeGroups,
  enableCustomEmail,
  enableCustomPhoneNumber,
  inputName,
  inputLabel = 'Search',
  inputHelperText,
  inputError,
  inputRequired,
  fullWidth = true,
  filterSelectedOptions = false,
  clearOnBlur = false,
  selectOnFocus = true,
  handleHomeEndKeys = true,
  value,
  multiple,
  inputValue: controlledInputValue,
  onInputChange,
  ...props
}: UsersAutocompleteProps<
  Multiple,
  DisableClearable,
  IncludeGroups,
  EnableCustomEmail,
  EnableCustomPhoneNumber
>): JSX.Element {
  const usersQuery = useAsync<GetUsersResponse>();
  const phoneLookupQuery = useAsync<PhoneNumber[]>();
  const [customOption, setCustomOption] = React.useState<BaseValue<
    IncludeGroups,
    EnableCustomEmail,
    EnableCustomPhoneNumber
  > | null>(null);

  /**
   * We have to dynamically set the no options text based on the input value
   * and results coming from each request.
   */
  const [noOptionsText, setNoOptionsText] = React.useState(utils.INPUT_EMPTY);

  /**
   * Since the actual request is debounced, we use this flag to simulate a
   * loading state right before making the request.
   */
  const [isAboutToFetchUsers, setIsAboutToFetchUsers] = React.useState(false);

  const fetchUsers = useAsyncDebounce((search: string) => {
    setIsAboutToFetchUsers(false);
    if (!search) return;
    void usersQuery
      .run(
        getUsers(
          {
            ...utils.defaultParams,
            ...getUsersParams(),
            ...(includeGroups && { includeGroups: 1 }),
            ...(Boolean(search) && { search }),
          },
          userController?.signal
        )
      )
      .then((res) => {
        /**
         * If no results are found and the custom email/phone props are enabled,
         * we try to add a custom option if applicable. We default to empty
         * arrays in case the users endpoint fails for any reason, this will
         * allow the user to use a custom field regardless of the previous
         * request.
         */
        const users = res instanceof Error ? [] : res.users;
        const groups = res instanceof Error ? [] : res.groups ?? [];
        const resultsFound = users.length > 0 || groups.length > 0;

        if (!resultsFound) {
          setNoOptionsText(utils.NO_RESULTS);
        }

        /**
         * Check if the input is an email.
         */
        if (
          enableCustomEmail &&
          isEmailValid(search) &&
          utils.isValueNotSelected(value, search)
        ) {
          setCustomOption({
            id: null,
            type: 'email',
            label: `Use ${search}`,
            value: search,
            formattedValue: search,
          } as BaseValue<
            IncludeGroups,
            EnableCustomEmail,
            EnableCustomPhoneNumber
          >);
          return;
        }

        /**
         * Check if the input is a phone number.
         */
        if (enableCustomPhoneNumber && utils.isPossiblePhoneNumber(search)) {
          void phoneLookupQuery.run(
            phoneLookup(search, lookupController?.signal).then((numbers) => {
              const number = numbers[0] as PhoneNumber | undefined;
              if (
                number &&
                utils.isValueNotSelected(value, number.phoneNumber)
              ) {
                const formattedNumber =
                  utils
                    .parsePhoneNumber(number.phoneNumber)
                    ?.formatInternational() ?? number.phoneNumber;
                setCustomOption({
                  id: null,
                  type: 'phone-number',
                  label: `Use ${formattedNumber}`,
                  value: number.phoneNumber,
                  formattedValue: formattedNumber,
                } as BaseValue<
                  IncludeGroups,
                  EnableCustomEmail,
                  EnableCustomPhoneNumber
                >);
              }
              return numbers;
            })
          );
          return;
        }
      });
  }, waitForMs);

  const options = React.useMemo(() => {
    let options: BaseValue<
      IncludeGroups,
      EnableCustomEmail,
      EnableCustomPhoneNumber
    >[] = [];
    if (usersQuery.data) {
      options = [
        ...(usersQuery.data?.groups ?? []),
        ...usersQuery.data.users,
      ] as BaseValue<
        IncludeGroups,
        EnableCustomEmail,
        EnableCustomPhoneNumber
      >[];
    }
    if (customOption) {
      options.push(customOption);
    }
    return options;
  }, [customOption, usersQuery.data]);

  const helperText =
    inputHelperText ??
    (enableCustomPhoneNumber
      ? utils.DEFAULT_HELPER_TEXT_WITH_CUSTOM_PHONE_NUMBER
      : utils.DEFAULT_HELPER_TEXT);

  return (
    <Autocomplete
      value={value}
      freeSolo={false}
      multiple={multiple}
      fullWidth={fullWidth}
      clearOnBlur={clearOnBlur}
      selectOnFocus={selectOnFocus}
      handleHomeEndKeys={handleHomeEndKeys}
      loading={
        isAboutToFetchUsers ||
        usersQuery.isLoading ||
        phoneLookupQuery.isLoading
      }
      options={options}
      getOptionLabel={utils.getOptionLabel}
      filterSelectedOptions={filterSelectedOptions}
      filterOptions={(x) => x}
      isOptionEqualToValue={utils.isOptionEqualToValue}
      noOptionsText={noOptionsText}
      inputValue={controlledInputValue}
      onInputChange={(event, rawNewInputValue, reason) => {
        /**
         * Abort the current requests each time the input is changed. This is
         * performed to avoid race conditions from multiple requests being
         * dispatched and resolving close to each other.
         */
        userController?.abort();
        lookupController?.abort();
        userController = new AbortController();
        lookupController = new AbortController();

        const newInputValue = rawNewInputValue.trim();

        if (reason === 'reset' || !newInputValue) {
          setNoOptionsText(utils.INPUT_EMPTY);
        }

        /**
         * Selecting a value from the list triggers this prop as well so we
         * check for the reason to avoid triggering another search request
         * along with the input value.
         */
        if (reason === 'input') {
          setIsAboutToFetchUsers(Boolean(newInputValue));
          usersQuery.setData({ users: [] });
          setCustomOption(null);
          fetchUsers(newInputValue);
        }

        onInputChange?.(event, rawNewInputValue, reason);
      }}
      renderOption={(props, option, state) => {
        if (utils.isTemporaryUser(option)) {
          return (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          );
        }

        return (
          <Box
            {...props}
            key={option.id}
            component="li"
            sx={{
              backgroundColor: state.selected
                ? utils.selectedBgColor
                : undefined,
              '&:not(:last-child)': {
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              },
            }}
          >
            <Box width="100%">
              {utils.isExistingUser(option) ? (
                <>
                  <Typography component="div" variant="body2">
                    {getFullName(option)}
                  </Typography>
                  {utils.shouldDisplayRoleDetails(option.role) && (
                    <Typography component="div" variant="caption">
                      <Typography
                        component="span"
                        variant="inherit"
                        fontWeight={700}
                        color={utils.getRoleColor(option.role)}
                      >
                        {option.role}
                      </Typography>
                      {utils.shouldDisplayRoleExtraDetails(option.role) &&
                        utils.getRoleExtraDetails(option)}
                    </Typography>
                  )}
                  {utils.shouldDisplayEmail(option) && (
                    <Typography component="div" variant="caption">
                      {utils.getEmail(option)}
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  <Typography component="div" variant="body2">
                    {option.name}
                  </Typography>
                  <Typography
                    component="div"
                    variant="caption"
                    fontWeight={700}
                    color="secondary.main"
                  >
                    Group
                  </Typography>
                  <Typography
                    component="div"
                    variant="caption"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {utils.getUsersInGroup(option)}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...getTextFieldProps({ params, multiple, inputRequired })}
          name={inputName}
          label={inputLabel}
          helperText={helperText}
          error={inputError}
        />
      )}
      renderTags={(options, getTagProps) =>
        options.map((option, index) => (
          /**
           * Disabling since `getTagProps` returns a `key` prop but with the
           * spread the linter can't "see" it.
           */
          // eslint-disable-next-line react/jsx-key
          <Chip
            label={utils.getOptionLabel(option)}
            {...getTagProps({ index })}
          />
        ))
      }
      {...props}
    />
  );
}
