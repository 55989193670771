/**
 * TECH DEBT
 * - The following import (and package) probably won't be needed once
 * `react-table` (v7-) is updated to latest (`@tanstack/react-table` (v8)).
 */
import 'regenerator-runtime';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';
import { RouterProvider } from 'react-router-dom';

import '@fullcalendar/react/dist/vdom';

import { ThemeProvider } from '#lib/emotion';
import { queryClient } from '#lib/react-query/client';
import { createRouter } from './routes/router';

import './index.css';

const router = createRouter(queryClient);

const anchorOrigin = { horizontal: 'center', vertical: 'top' } as const;

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <SnackbarProvider anchorOrigin={anchorOrigin}>
          <CssBaseline />
          <RouterProvider router={router} />
        </SnackbarProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
    </QueryClientProvider>
  </React.StrictMode>
);
