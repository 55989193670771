import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import useCustomBranding from '#hooks/custom-branding';

/**
 * Theme provider with custom branding. This component isn't defined in
 * `main.tsx` alongside the other providers because it needs to be rendered
 * within the `QueryClientProvider` in order to make use of the custom branding
 * hook due to the usage of `useQuery` within the hook.
 */
export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const theme = useCustomBranding();

  return <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>;
}
