import type { EventInput } from '@fullcalendar/react';
import type { BackgroundEvent, Schedule, Type } from '#types/appointment';

import { isPast } from 'date-fns';

import { green, lavander, mendTeal, red } from '#lib/mui/colors';
import { formatUTCStringWithTimezone } from '#utils/dates';

export type ScheduleUserProps = {
  userName: string;
  timezone: string;
  handleOpenCalendar?: () => void;
};

const getBackgroundColor = (
  appointmentType: Type,
  startDate: string,
  timeZone: string
) => {
  if (isPast(new Date(formatUTCStringWithTimezone(startDate, timeZone)))) {
    return red[800];
  }
  switch (appointmentType) {
    case 'Office Video':
      return mendTeal[500];
    case 'Phone':
      return lavander[500];
    case 'In Office':
      return mendTeal[500];
    default:
      return green[900];
  }
};

export const createEventsFromBackgroundEvents = (
  events: BackgroundEvent[]
): EventInput[] =>
  events.map((event) => ({
    ...event,
    color: red[300],
    display: 'background',
    editable: false,
  }));

export const createEventsFromSchedule = (
  events: Schedule[],
  timeZone: string
): EventInput[] =>
  events.map((event) => {
    const backgroundColor = getBackgroundColor(
      event.appointmentType?.type ?? 'Video',
      event.startDate,
      timeZone
    );
    return {
      backgroundColor,
      color: backgroundColor,
      start: formatUTCStringWithTimezone(event.startDate, timeZone),
      end: formatUTCStringWithTimezone(event.endDate, timeZone),
      textColor: 'white',
      title: event.groupId
        ? 'Group Appointment'
        : `${event.patientFirstName} ${event.patientLastName}`,
      editable: false,
    };
  });
