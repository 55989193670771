export default {
  50: '#d1f4ff',
  100: '#afdff7',
  200: '#92c5e2',
  300: '#71accc',
  400: '#5698ba',
  500: '#4185a6',
  600: '#287697',
  700: '#166280', // Primary
  800: '#00526e',
  900: '#003f57',
  1000: '#012331', // Dark
};
