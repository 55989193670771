export default {
  50: '#fff0ee',
  100: '#ffdede',
  200: '#ff9d9d',
  300: '#f97777',
  400: '#ff6a4b',
  500: '#f7482a',
  600: '#db3a21',
  700: '#bb2519',
  800: '#ae1e12',
  900: '#9f1205',
};
