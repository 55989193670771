export default {
  50: '#fffcf5',
  100: '#f5eebe',
  200: '#ede594',
  300: '#e5db6a',
  400: '#ded34a',
  500: '#d8cc26',
  600: '#e9cc36',
  700: '#c0a02a',
  800: '#846a0e',
  900: '#604c08',
};
