import type { Column } from 'react-table';
import type { CustomTheme } from '#lib/mui/theme';
import type { GetUsersResponse, User } from '#services/api/user';
import type {
  Appointment,
  AppointmentTransferStatus,
  AssignedUser,
} from '#types/appointment';
import type {
  CellRendererAllUsersProps,
  CellRendererTransferStatusProps,
  UserType,
} from './utils';

import * as React from 'react';
import {
  Button,
  Chip,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useSnackbar } from 'notistack';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import { ListDataTable } from '#components/DataTable';
import * as Modal from '#components/Modal';
import { ProviderAvailability } from '#components/ProviderAvailability/ProviderAvailability';
import SearchBar from '#components/SearchBar';
import TabPanel from '#components/TabPanel';
import useAsync from '#hooks/async';
import usePlaceholder from '#hooks/placeholder';
import { green } from '#lib/mui/colors';
import { styled } from '#lib/mui/styled';
import { confirmAppointmentTransfer } from '#services/api/appointment';
import { getUsers } from '#services/api/user';
import { currentTimezone, formatUTCStringWithTimezone } from '#utils/dates';
import { getFullName } from '#utils/user';
import Action from './Action';
import * as Cards from './Cards';
import * as Cells from './Cells';
import Name from './Name';
import { globalFilter, globalFilterTransferStatus } from './utils';

const BoldTypography = styled(Typography)(() => ({
  fontWeight: 700,
}));

const SelectedItemStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: green[50],
}));

const noTransferStatus = 'No transfer status found.';
const errorTransferStatus = 'There was an error loading the transfer status.';

const noUsersMessage = 'No users found.';
const errorUsersMessage = 'There was an error loading the users.';

type TransferVisitModalProps = {
  appointment: Appointment;
  appointmentStatuses: AppointmentTransferStatus[];
  isOpen: boolean;
  onDismiss: () => void;
  onSuccess: (appointment: Appointment) => void;
};

type SlotRange = {
  endDate: string;
  startDate: string;
  id?: string;
};

const initialRange = {
  endDate: '',
  startDate: '',
  id: '',
};

const columns: Column<User>[] = [
  {
    id: 'actions',
    Header: () => null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    Cell: Cells.AllUsersAction,
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  {
    id: 'name',
    Header: 'User',
    accessor: getFullName,
    Cell: Cells.Name,
  },
  {
    id: 'email',
    Header: 'Email',
    accessor: 'email',
  },
];

const transferStatusColumns: Column<AppointmentTransferStatus>[] = [
  {
    id: 'actions',
    Header: '',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    Cell: Cells.TransferStatusAction,
    Filter: () => null,
  },
  {
    id: 'name',
    Header: 'Transfer Status',
    accessor: 'name',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    Cell: Cells.TransferStatusInfo,
    Filter: () => null,
  },
];

const defaultSortBy = [{ id: 'name', desc: false }];
const defaultSortByTransferStatus = [{ id: 'name', desc: false }];

/**
 * TODO: implement pooling for slot availability
 * an inconvenience was evidenced when there is
 * an available slot and is about to expire, the idea
 * is to implement a pool to solve it.
 */
export default function TransferVisitModal({
  isOpen,
  onDismiss,
  appointment,
  appointmentStatuses,
  onSuccess,
}: TransferVisitModalProps): JSX.Element {
  const [view, setView] = React.useState<'status' | 'complete'>(
    appointment.appointmentStatusId ? 'complete' : 'status'
  );

  const [currentStatusTypeId, setCurrentStatusTypeId] = React.useState(
    appointment.appointmentStatusId
  );

  const [userType, setUserType] = React.useState<UserType>('all');

  const [transferUser, setTransferUser] = React.useState<
    User | AssignedUser | null
  >(appointment.assignedUser);

  const fullUserDataQuery = useAsync<User | undefined>();
  const { run: fullUserDataQueryRun } = fullUserDataQuery;
  const hasFetchedUserData = React.useRef(false);

  /**
   * If the user is initialized with the `appointment.assignedUser`, we need to
   * get the full data so the thumbnail/specialty/credentials are displayed
   * properly. We make use of `getUsers` instead of `getUser` because the
   * latter doesn't include the specialty/credentials in the response.
   */
  React.useEffect(() => {
    if (!appointment.assignedUser || hasFetchedUserData.current) {
      return;
    }
    void fullUserDataQueryRun(
      getUsers({ id: appointment.assignedUser.id }).then((res) => res.users[0])
    ).then((result) => {
      if (!(result instanceof Error) && result) {
        setTransferUser(result);
      }
      hasFetchedUserData.current = true;
    });
  }, [appointment, fullUserDataQueryRun]);

  const [selectedSlot, setSelectedSlot] =
    React.useState<SlotRange>(initialRange);

  const [timezone, setTimezone] = React.useState(`${currentTimezone}-mine`);

  const { enqueueSnackbar } = useSnackbar();

  const {
    data: transferUsersData,
    run: transferUsersRun,
    status: transferUsersStatus,
  } = useAsync<GetUsersResponse>();

  React.useEffect(() => {
    void transferUsersRun(
      getUsers({
        order: 'lastName ASC',
        limit: 10_000,
        role: ['Staff', 'Provider', 'Provider Admin', 'Org Admin'],
      })
    );
  }, [transferUsersRun]);

  const { run: confirmTransferRun, status: confirmTransferStatus } =
    useAsync<Appointment>();

  const allUsers = React.useMemo(
    () => transferUsersData?.users ?? [],
    [transferUsersData]
  );

  const handleMainActionClick = () => {
    if (view === 'status') {
      setView('complete');
      return;
    }

    void confirmTransferRun(
      confirmAppointmentTransfer(appointment.id, {
        appointmentStatusId: currentStatusTypeId!,
        assignedUserId: transferUser ? transferUser.id : null,
        ...(selectedSlot !== initialRange && {
          startDate: zonedTimeToUtc(
            new Date(selectedSlot.startDate),
            currentTimezone
          ).toISOString(),
          endDate: zonedTimeToUtc(
            new Date(selectedSlot.endDate),
            currentTimezone
          ).toISOString(),
        }),
      })
    ).then((res) => {
      if (res instanceof Error) {
        enqueueSnackbar('Could not transfer appointment', { variant: 'error' });
        return;
      }

      enqueueSnackbar('Appointment was successfully transferred', {
        variant: 'success',
      });
      onSuccess(res);
    });
  };

  const handleSlotSelection = (slot: SlotRange) => setSelectedSlot(slot);

  const handleUserSelection = (user: User) => {
    setTransferUser(!transferUser ? user : null);
  };

  const handleRemove = () => {
    setTransferUser(null);
    setSelectedSlot(initialRange);
  };

  const allUsersInstance = useTable<User>(
    {
      columns: columns,
      data: allUsers,
      initialState: {
        pageSize: 5,
        globalFilter: '',
        sortBy: defaultSortBy,
        pageIndex: 0,
      },
      globalFilter,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { setGlobalFilter: setSearchAllUsers } = allUsersInstance;

  const searchAllUsers =
    typeof allUsersInstance.state.globalFilter !== 'string'
      ? ''
      : allUsersInstance.state.globalFilter;

  const transferStatusInstance = useTable<AppointmentTransferStatus>(
    {
      columns: transferStatusColumns,
      data: appointmentStatuses,
      initialState: {
        pageSize: 5,
        globalFilter: '',
        sortBy: defaultSortByTransferStatus,
        pageIndex: 0,
      },
      globalFilter: globalFilterTransferStatus,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { setGlobalFilter: setStatusSearchTerm } = transferStatusInstance;

  const statusSearchTerm =
    typeof allUsersInstance.state.globalFilter !== 'string'
      ? ''
      : allUsersInstance.state.globalFilter;

  const handleStatusType = (event: React.ChangeEvent<HTMLInputElement>) =>
    setCurrentStatusTypeId(Number(event.target.value));

  const handleTabChange = (
    _event: React.SyntheticEvent<Element, Event>,
    userType: UserType
  ) => {
    setSelectedSlot(initialRange);
    setUserType(userType);
    setTransferUser(null);
    setSearchAllUsers('');
  };

  const isMobile = useMediaQuery<CustomTheme>((theme) =>
    theme.breakpoints.down('sm')
  );

  const renderCurrentTransferStatus = () => {
    const selectedTransferStatus = appointmentStatuses.find(
      (status) => status.id === currentStatusTypeId
    );
    return selectedTransferStatus ? (
      <SelectedItemStack>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'start' : 'center'}
        >
          <Chip
            data-testid="status-chip"
            label={selectedTransferStatus.name}
            sx={{
              color: selectedTransferStatus.textColor,
              backgroundColor: selectedTransferStatus.backgroundColor,
            }}
          />
          <Typography
            mt={(theme) => (isMobile ? `${theme.spacing(1)} !important` : 0)}
          >
            {selectedTransferStatus.description}
          </Typography>
        </Stack>
        <Action action="edit" onClick={() => setView('status')} />
      </SelectedItemStack>
    ) : null;
  };

  const transferStatusPlaceholder = usePlaceholder('Transfer status');
  const userPlaceholder = usePlaceholder('User');

  /**
   * We can't tell if the appointment's time came from an slot or not, so we
   * don't include the selected slot as part of the condition.
   */
  const requiresConfirmation =
    currentStatusTypeId !== appointment.appointmentStatusId ||
    transferUser?.id !== appointment.assignedUser?.id;

  return (
    <Modal.Root
      maxWidth="xl"
      aria-labelledby="transfer-visit-title"
      aria-describedby="transfer-visit-description"
      open={isOpen}
      isLoading={confirmTransferStatus === 'loading'}
      onClose={onDismiss}
      requiresConfirmation={requiresConfirmation}
    >
      <Modal.Header>
        <Modal.Title id="transfer-visit-title">
          Transfer Appointment
        </Modal.Title>
        <Modal.Description id="transfer-visit-description">
          Please select an appointment transfer
        </Modal.Description>
      </Modal.Header>

      <Modal.Content>
        {view === 'status' ? (
          <>
            <SearchBar
              sx={{ mb: 2 }}
              fullWidth
              placeholder={transferStatusPlaceholder}
              value={statusSearchTerm}
              onChange={setStatusSearchTerm}
            />
            <ListDataTable<
              AppointmentTransferStatus,
              CellRendererTransferStatusProps
            >
              instance={transferStatusInstance}
              getCellRendererProps={() => ({
                currentStatusTypeId,
                handleStatusType,
              })}
              isEmptyMessage={noTransferStatus}
              isRejectedMessage={errorTransferStatus}
              Card={Cards.TransferStatusCard}
            />
          </>
        ) : (
          <>
            <BoldTypography mb={2}>Selected Transfer:</BoldTypography>
            {renderCurrentTransferStatus()}
            <BoldTypography my={2}>
              Assign to Staff or Provider (Optional)
            </BoldTypography>
            {!transferUser ? (
              <>
                <Stack mb={3}>
                  <Tabs
                    value={userType}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    onChange={handleTabChange}
                  >
                    <Tab value="all" label="All Users" />
                    <Tab value="provider" label="Provider Availability" />
                  </Tabs>
                </Stack>
                {userType === 'all' && (
                  <SearchBar
                    sx={{ mb: 2 }}
                    fullWidth
                    placeholder={userPlaceholder}
                    value={searchAllUsers}
                    onChange={setSearchAllUsers}
                  />
                )}
                <TabPanel value={userType} index="all">
                  <ListDataTable<User, CellRendererAllUsersProps>
                    instance={allUsersInstance}
                    status={transferUsersStatus}
                    getCellRendererProps={() => ({
                      handleUserSelection,
                    })}
                    isEmptyMessage={noUsersMessage}
                    isRejectedMessage={errorUsersMessage}
                    Card={Cards.SharedUsersCard}
                  />
                </TabPanel>
                <TabPanel value={userType} index="provider">
                  <ProviderAvailability
                    appointment={appointment}
                    getCellRenderedProps={() => ({
                      handleUserSelection,
                      transferUser,
                      handleSlotSelection,
                      selectedSlot,
                      timezone,
                      currentTimezone,
                    })}
                    patientTimezone={appointment?.patient.timeZone}
                    timezone={timezone}
                    onTimezoneChange={setTimezone}
                  />
                </TabPanel>
              </>
            ) : (
              <SelectedItemStack>
                <Name user={transferUser}>
                  {selectedSlot.startDate
                    ? formatUTCStringWithTimezone(
                        selectedSlot.startDate,
                        timezone.split('-')[0] as string, // FIXME - enforce type check
                        'MMM dd hh:mma'
                      )
                    : (transferUser as User).email ?? null}
                </Name>
                <Action action="remove" onClick={handleRemove} />
              </SelectedItemStack>
            )}
          </>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Modal.DismissButton />
        <Button disabled={!currentStatusTypeId} onClick={handleMainActionClick}>
          {view === 'status' ? 'Continue' : 'Complete Transfer'}
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}
