export default {
  50: '#fde5ec',
  100: '#fabdd0',
  200: '#f792b0',
  300: '#f46791',
  400: '#ef4377',
  500: '#ec2460',
  600: '#db215d',
  700: '#c51d58',
  800: '#b01855',
  900: '#8b114d',
  1000: '#ec2663',
};
